<template>
  <!-- 嵌入页面 -->
  <iframe
    name="iframeChild"
    id="iframeChild"
    v-bind:src="pageUrl"
    width="100%"
    height="100%"
    frameborder="0"
    scrolling="no"
    ref="iframeDom"
  ></iframe>
</template>

<script>
export default {
  props: {
    pageUrl: {
      // 嵌入页面URL
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 动态获取页面的高度
      // iframeHeight:500,
    };
  },
};
</script>

<style scoped lang="scss">
.docker-server {
  width: 100%;
}
</style>