<!-- 标情发现-嵌入页面 -->
<template>
  <div class="div_content">
    <embedded-page :pageUrl="pageUrl" />
  </div>
</template>

<script>
/**
 * 标情发现
 */
import EmbeddedPage from "../homePage/components/EmbeddedPage.vue"; //嵌入页面组件
import * as request from "./api"; // api文件

export default {
  components: {
    EmbeddedPage,
  },
  data() {
    return {
      pageUrl: "", // 页面URL
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    // 获取token，将页面地址拼接
    async getToken() {
      let res = await request.queryUrl();
      this.pageUrl = `http://zhbq.istarshine.com/cloudcc/#/home?token=${
        res.data.token
      }&industrys=${encodeURI("行业1,行业2")}&ownerid=123`;
    },
  },
};
</script>

<style lang='scss' scoped>
.div_content {
  height: 100%;
}
</style>